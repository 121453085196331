import React, { useState } from 'react'
import { Col, Row, Input } from 'antd'
import { ArrayIconType } from '..'

const gaxonIconsObj = {
  add: 'add',
  'add-circle': 'add-circle',
  affix: 'affix',
  alert: 'alert',
  'alert-new': 'alert-new',
  'all-contacts': 'all-contacts',
  amchart: 'amchart',
  anchor: 'anchor',
  apps: 'apps',
  'apps-new': 'apps-new',
  'arrow-left': 'arrow-left',
  'arrow-right': 'arrow-right',
  attachment: 'attachment',
  'auth-screen': 'auth-screen',
  autocomplete: 'autocomplete',
  avatar: 'avatar',
  backtop: 'backtop',
  badge: 'badge',
  'basic-calendar': 'basic-calendar',
  birthday: 'birthday',
  'birthday-new': 'birthday-new',
  bitcoin: 'bitcoin',
  breadcrumb: 'breadcrumb',
  burger: 'burger',
  button: 'button',
  calendar: 'calendar',
  'calendar-new': 'calendar-new',
  callout: 'callout',
  camera: 'camera',
  'camera-2': 'camera-2',
  card: 'card',
  'cards-list-view': 'cards-list-view',
  carousel: 'carousel',
  cascader: 'cascader',
  chart: 'chart',
  'chart-area': 'chart-area',
  'chart-area-new': 'chart-area-new',
  'chart-bar': 'chart-bar',
  'chart-composed': 'chart-composed',
  'chart-line': 'chart-line',
  'chart-pie': 'chart-pie',
  'chart-radar': 'chart-radar',
  'chart-radial': 'chart-radial',
  'chart-scatter': 'chart-scatter',
  'chart-tree': 'chart-tree',
  'charvlet-down': 'charvlet-down',
  'charvlet-left': 'charvlet-left',
  'charvlet-right': 'charvlet-right',
  'charvlet-up': 'charvlet-up',
  chat: 'chat',
  'chat-bubble': 'chat-bubble',
  'chat-new': 'chat-new',
  check: 'check',
  'check-circle-o': 'check-circle-o',
  'check-cricle': 'check-cricle',
  'check-square': 'check-square',
  'check-square-o': 'check-square-o',
  'chevron-down': 'chevron-down',
  'chevron-left': 'chevron-left',
  'chevron-right': 'chevron-right',
  'chevron-up': 'chevron-up',
  circle: 'circle',
  'circle-o': 'circle-o',
  ckeditor: 'ckeditor',
  close: 'close',
  'close-circle': 'close-circle',
  collapse: 'collapse',
  company: 'company',
  components: 'components',
  compose: 'compose',
  contacts: 'contacts',
  copy: 'copy',
  crm: 'crm',
  crypto: 'crypto',
  'culture-calendar': 'culture-calendar',
  'custom-view': 'custom-view',
  dasbhoard: 'dasbhoard',
  'data-display': 'data-display',
  'data-entry': 'data-entry',
  datepicker: 'datepicker',
  'default-timeline': 'default-timeline',
  diamond: 'diamond',
  divider: 'divider',
  donut: 'donut',
  down: 'down',
  draft: 'draft',
  'drag-and-drop': 'drag-and-drop',
  dropdown: 'dropdown',
  edit: 'edit',
  editor: 'editor',
  'ellipse-h': 'ellipse-h',
  'ellipse-v': 'ellipse-v',
  email: 'email',
  error: 'error',
  'error-404': 'error-404',
  'error-500': 'error-500',
  etherium: 'etherium',
  exclamation: 'exclamation',
  expand: 'expand',
  extensions: 'extensions',
  'extra-components': 'extra-components',
  eye: 'eye',
  facebook: 'facebook',
  family: 'family',
  feedback: 'feedback',
  files: 'files',
  filter: 'filter',
  'filter-circle': 'filter-circle',
  folder: 'folder',
  'folder-o': 'folder-o',
  font: 'font',
  'forgot-password': 'forgot-password',
  forward: 'forward',
  'forward-o': 'forward-o',
  frequent: 'frequent',
  'geo-location': 'geo-location',
  graduation: 'graduation',
  growth: 'growth',
  home: 'home',
  'hotel-booking': 'hotel-booking',
  icon: 'icon',
  image: 'image',
  important: 'important',
  'important-o': 'important-o',
  inbox: 'inbox',
  'inbuilt-apps': 'inbuilt-apps',
  input: 'input',
  inputnumber: 'inputnumber',
  'invert-color': 'invert-color',
  keyboard: 'keyboard',
  like: 'like',
  'like-o': 'like-o',
  link: 'link',
  'lising-dbrd': 'lising-dbrd',
  'list-select-o': 'list-select-o',
  'listing-dbrd': 'listing-dbrd',
  litcoin: 'litcoin',
  localeprovider: 'localeprovider',
  location: 'location',
  'lock-screen': 'lock-screen',
  'long-arrow': 'long-arrow',
  'long-arrow-down': 'long-arrow-down',
  'long-arrow-left': 'long-arrow-left',
  'long-arrow-right': 'long-arrow-right',
  'long-arrow-up': 'long-arrow-up',
  'mail-open': 'mail-open',
  'map-clustering': 'map-clustering',
  'map-directions': 'map-directions',
  'map-drawing': 'map-drawing',
  'map-event-listener': 'map-event-listener',
  'map-google': 'map-google',
  'map-km-layer': 'map-km-layer',
  'map-overlay': 'map-overlay',
  'map-popup-info': 'map-popup-info',
  'map-selectable': 'map-selectable',
  'map-simple': 'map-simple',
  'map-street-view': 'map-street-view',
  'map-styled': 'map-styled',
  'map-traffic-layer': 'map-traffic-layer',
  megaphone: 'megaphone',
  mention: 'mention',
  menu: 'menu',
  'menu-down': 'menu-down',
  'menu-fold': 'menu-fold',
  'menu-left': 'menu-left',
  'menu-lines': 'menu-lines',
  'menu-right': 'menu-right',
  'menu-select': 'menu-select',
  'menu-unfold': 'menu-unfold',
  'menu-up': 'menu-up',
  message: 'message',
  mic: 'mic',
  modal: 'modal',
  navigation: 'navigation',
  noodles: 'noodles',
  notification: 'notification',
  'notification-new': 'notification-new',
  orders: 'orders',
  pagination: 'pagination',
  phone: 'phone',
  picker: 'picker',
  pizza: 'pizza',
  'plain-list-divider': 'plain-list-divider',
  'plain-list-view': 'plain-list-view',
  popconfirm: 'popconfirm',
  popover: 'popover',
  'popup-calendar': 'popup-calendar',
  'pricing-table': 'pricing-table',
  'product-grid': 'product-grid',
  'product-list': 'product-list',
  profile: 'profile',
  profile2: 'profile2',
  progress: 'progress',
  queries: 'queries',
  'question-circle': 'question-circle',
  'quote-backward': 'quote-backward',
  'quote-forward': 'quote-forward',
  radiobutton: 'radiobutton',
  refer: 'refer',
  'rendaring-calendar': 'rendaring-calendar',
  reply: 'reply',
  'reply-o': 'reply-o',
  'reset-password': 'reset-password',
  'revenue-new': 'revenue-new',
  ripple: 'ripple',
  schedule: 'schedule',
  search: 'search',
  'search-new': 'search-new',
  select: 'select',
  'selectable-map': 'selectable-map',
  sent: 'sent',
  setting: 'setting',
  'shopping-cart': 'shopping-cart',
  shuffle: 'shuffle',
  signin: 'signin',
  signup: 'signup',
  slider: 'slider',
  social: 'social',
  spam: 'spam',
  spin: 'spin',
  star: 'star',
  'star-half': 'star-half',
  'star-o': 'star-o',
  stats: 'stats',
  steps: 'steps',
  'styled-map': 'styled-map',
  'sweet-alert': 'sweet-alert',
  switch: 'switch',
  tab: 'tab',
  table: 'table',
  'table-data': 'table-data',
  'table-general': 'table-general',
  tag: 'tag',
  'tag-new': 'tag-new',
  'tag-o': 'tag-o',
  tasks: 'tasks',
  team: 'team',
  testimonial: 'testimonial',
  'thumb-up': 'thumb-up',
  'thumbs-down': 'thumbs-down',
  'ticket-new': 'ticket-new',
  tickets: 'tickets',
  timeline: 'timeline',
  'timeline-left-align': 'timeline-left-align',
  'timeline-new': 'timeline-new',
  'timeline-with-icons': 'timeline-with-icons',
  timepicker: 'timepicker',
  tooltip: 'tooltip',
  transfer: 'transfer',
  translation: 'translation',
  trash: 'trash',
  tree: 'tree',
  treeselect: 'treeselect',
  'uncheck-squire': 'uncheck-squire',
  'uncheck-squire-o': 'uncheck-squire-o',
  upload: 'upload',
  user: 'user',
  'user-o': 'user-o',
  view: 'view',
  'view-o': 'view-o',
  visits: 'visits',
  wall: 'wall',
  widgets: 'widgets',
  wysiwyg: 'wysiwyg',
}
const gaxonIconsKeys = Object.keys(gaxonIconsObj)

const Icons: React.FC<{
  onChange?: (iconIdentity: string, iconType: string) => void
}> = (props) => {
  const [search, setSearch] = useState<string>('')
  const [iconArray, setIconArray] = useState(gaxonIconsKeys)

  const onChange = (key: string) => {
    props?.onChange?.(key, ArrayIconType.gaxon)
  }

  const onSearchChange = (e: any) => {
    const val = e.target.value
    setSearch(val)
    if (val && val != '')
      setIconArray((prev) =>
        prev.filter((svgIdentifier) =>
          svgIdentifier.toLocaleLowerCase().includes(val.toLocaleLowerCase())
        )
      )
    else {
      setIconArray(gaxonIconsKeys)
    }
  }

  return (
    <>
      <Row className="gx-mb-2">
        <Col span={24}>
          <Input
            style={{ width: '100%' }}
            value={search}
            onChange={onSearchChange}
          />
        </Col>
      </Row>
      <Row className="glyphs css-mapping">
        {iconArray.map((iconIdentity: string) => (
          <Col key={`icon-${iconIdentity}`} xl={4} lg={6} md={6} sm={8} xs={12}>
            <div
              className="gx-icon-views"
              onClick={() => {
                onChange(`${iconIdentity}`)
              }}
            >
              <i className={`icon icon-${iconIdentity}`} />
              <span className="gx-icon-text">{iconIdentity}</span>
            </div>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Icons
