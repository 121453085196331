import React from "react";
import { Button, /* Checkbox, */ Form, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { connect } from "dva";
// import { router } from "dva";

import IntlMessages from "@src/util/IntlMessages";
import CircularProgress from "@src/components/CircularProgress/index";
import { StoreState } from "@src/interfaces";
// import * as authServices from '@src/services/auth';
import logoBackground from "../assets/images/Evipcare-Logo.png";
// import logo from '../assets/images/vdslogo.png';
// import { IS_DEBUG } from '@src/constants/constants';
const FormItem = Form.Item;
const SITE_KEY = "6LfcZx4fAAAAACKpO5LW4YWI1LUfce6uutgqveDj";

class SignIn extends React.Component<
  {
    dispatch?: any;
    showMessage?: any;
    history?: any;
    authUser?: any;
    loader?: any;
    alertMessage?: any;
  },
  {
    tokenCapcha?: string;
    captcha: string;
    accountKitToken?: string;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      captcha: "",
      accountKitToken: "",
    };
  }

  form = React.createRef<any>();

  handleSubmit = (values: Record<string, any>) => {
    const { dispatch } = this.props;
    dispatch({
      type: "auth/showAuthLoader",
    });
    // dispatch({
    //   type: 'auth/userSignIn',
    //   payload: {
    //     ...values,
    //     captchaId: this.state.captchaId,
    //     tokenCapcha: this.state.tokenCapcha,
    //   },
    // });
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token: any) => {
          console.log("@@token", token);
          dispatch({
            type: "auth/userSignIn",
            payload: {
              ...values,
              tokenCapcha: token,
            },
          });
        });
    });
    console.log("!!@@ready", window);
  };

  // async loadCaptcha() {
  //   //load captcha
  //   const captInfo = await authServices.getCaptcha();
  //   if (IS_DEBUG) {
  //     console.log(
  //       `🚀 ~ file: SignIn.tsx ~ line 57 ~ loadCaptcha ~ captInfo`,
  //       captInfo
  //     );
  //   }

  //   this.setState({
  //     captchaId: captInfo.data.id,
  //     captcha: captInfo.data.data,
  //     tokenCapcha: captInfo.data.tokenCapcha,
  //   });
  // }

  loadScriptByURL = (id: any, url: any, callback: any) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  componentDidMount() {
    this.loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    if (this.props.showMessage) {
      setTimeout(() => {
        dispatch({
          type: "auth/hideMessage",
        });
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  render() {
    const { showMessage, loader, alertMessage } = this.props;

    return (
      <div className="gx-app-login-wrap" >
        <div
          className="gx-app-login-container"
          style={{
            maxWidth: "900px",
            width: "100%",
          }}
        >
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content" style={{ width: "30%" }}>
              <div
                className="gx-app-logo-content-bg"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={logoBackground}
                  alt="Evipcare"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              {/* <div className="gx-app-logo-wid">
                <p>
                  <IntlMessages id="app.userAuth.getAccount" />
                </p>
              </div> */}
              <div className="gx-app-logo">
                <span
                  style={{ margin: "0 auto", verticalAlign: "center" }}
                >{` `}</span>
              </div>
            </div>
            <div className="gx-app-login-content">
              <h1 className="title-login">
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <Form
                ref={this.form}
                onFinish={this.handleSubmit}
                style={{ width: "60%" }}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem
                  name="username"
                  initialValue=""
                  rules={[
                    { required: true, message: "Vui lòng nhập tên đăng nhập!" },
                  ]}
                >
                  <Input
                    placeholder="Tên đăng nhập"
                    prefix={<UserOutlined />}
                  />
                </FormItem>
                <FormItem
                  name="password"
                  initialValue=""
                  rules={[
                    { required: true, message: "Vui lòng nhập mật khẩu!" },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Mật khẩu"
                    prefix={<LockOutlined />}
                  />
                </FormItem>
                {/* <FormItem>
                  <div
                    className='captcha'
                    dangerouslySetInnerHTML={{ __html: this.state.captcha }}
                  ></div>
                </FormItem>
                <FormItem name='captchaText'>
                  <Input
                    addonAfter={
                      <SyncOutlined
                        onClick={() => {
                          this.loadCaptcha();
                        }}
                      />
                    }
                  />
                </FormItem> */}
                <FormItem>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    <IntlMessages id="app.userAuth.login" />
                  </Button>
                </FormItem>
              </Form>
            </div>

            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {showMessage && alertMessage
              ? message.error(alertMessage.toString(), 10)
              : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: StoreState) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps)(SignIn);
