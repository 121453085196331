import React, { useEffect } from 'react';
import { Carousel } from 'antd';
import slide1 from "@src/assets/images/dashboard/bac-si-rieng-1920x1000.jpg";
import slide2 from "@src/assets/images/dashboard/11132-copy.jpg";

const Home: React.FC<any> = () => {
  useEffect(() => {
    /* request("@src/admin/banner/get-list-banner").then((rs: any) => {
      if (rs.data?.data) {
        setListBanner(rs.data?.data);
      }
    }); */
  }, []);

  return (
    <React.Fragment>
      <div>
        <Carousel autoplay>
          <div>
            <img
              src={slide1}
              style={{
                maxHeight: '600px',
                objectFit: 'cover',
              }}
            />
          </div>
          <div>
            <img
              src={slide2}
              style={{
                maxHeight: '600px',
                objectFit: 'cover',
              }}
            />
          </div>
        </Carousel>
      </div>
    </React.Fragment>
  );
};

export default Home;
