import local from '@src/util/local'
import {
  signInUserWithUserPasswordRequest,
  signOutRequest,
} from '../services/auth'
import { Model } from 'dva'
import { Action, Reducer, ReducersMapObject } from 'redux'

const authModel: Model = {
  namespace: 'auth',

  state: {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: local.get('user_id'),
    token: local.get('token'),
  },

  effects: {
    *userSignIn({ payload }, { put, call }) {
      const {
        username,
        password,
        captchaId,
        captchaText,
        accountKitToken,
        tokenCapcha,
      } = payload
      try {
        const res = yield call(
          signInUserWithUserPasswordRequest,
          username,
          password,
          captchaId,
          captchaText,
          accountKitToken,
          tokenCapcha
        )
        /* let res: any
        if(username == 'admin' && password == '123'){
          res = {
            data: {
              code: 0,
              data: {
                userInfo: {
                  id: 1,
                  name: 'phongnv',
                },
                token: 'fdfdsfdf',
              },
            },
          }
        }else {
          res = new Error('Sai thông tin!')
        } */
        if (res instanceof Error) {
          // message.error(res.message || 'Hệ thống bận, vui lòng quay lại sau!', 30);
          yield put({
            type: 'showAuthMessage',
            payload: res.message || 'Hệ thống bận, vui lòng quay lại sau!',
          })
        } else {
          const signInUser = res.data
          if (signInUser && signInUser.code == 0) {
            yield put({
              type: 'menu/getMenuData',
              payload: {
                role: signInUser.data.userInfo.role,
              },
            })
            localStorage.setItem(
              'user_id',
              JSON.stringify(signInUser.data.userInfo)
            )
            localStorage.setItem('token', signInUser.data.token)
            yield put({
              type: 'userSignInSuccess',
              payload: signInUser.data,
            })
          } else {
            // message.error((signInUser || {}).message || 'Hệ thống bận, vui lòng quay lại sau!', 30);
            yield put({
              type: 'showAuthMessage',
              payload:
                (signInUser || {}).message ||
                'Hệ thống bận, vui lòng quay lại sau!',
            })
          }
        }
      } catch (error: any) {
        console.error(
          `🚀 ~ file: auth.js ~ line 45 ~ *userSignIn ~ error`,
          error
        )
        yield put({
          type: 'showAuthMessage',
          payload: error.message || 'Hệ thống bận, vui lòng quay lại sau!',
        })
      }
    },
    *userSignOut({}, { call, put }) {
      try {
        const signOutUser = yield call(signOutRequest)
        if (signOutUser === undefined) {
          localStorage.removeItem('user_id')
          localStorage.removeItem('token')
          yield put({
            type: 'userSignOutSuccess',
            payload: signOutUser,
          })
        } else {
          yield put({
            type: 'showAuthMessage',
            payload: signOutUser.message,
          })
        }
      } catch (error) {
        yield put({
          type: 'showAuthMessage',
          payload: error,
        })
      }
    },
  },

  reducers: {
    setInitUrl(
      state,
      action: {
        payload: any
      }
    ) {
      return {
        ...state,
        initURL: action.payload,
      } as Reducer<any, Action<any>>
    },
    userSignOutSuccess(state) {
      return {
        ...state,
        authUser: null,
        token: null,
        initURL: '/',
        loader: false,
      } as Reducer<any, Action<any>>
    },
    showMessage(
      state,
      action: {
        payload: any
      }
    ) {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      } as Reducer<any, Action<any>>
    },
    hideMessage(state) {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      } as Reducer<any, Action<any>>
    },
    hideLoader(
      state,
      action: {
        payload: any
      }
    ) {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      } as Reducer<any, Action<any>>
    },
    showAuthLoader(state) {
      return {
        ...state,
        loader: true,
      } as Reducer<any, Action<any>>
    },
    hideAuthLoader(state) {
      return {
        ...state,
        loader: false,
      } as Reducer<any, Action<any>>
    },
    showAuthMessage(
      state,
      action: {
        payload: any
      }
    ) {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      } as Reducer<any, Action<any>>
    },
    userSignInSuccess(
      state,
      action: {
        payload: any
      }
    ) {
      return {
        ...state,
        loader: false,
        authUser: action.payload.userInfo,
        token: action.payload.token,
      } as Reducer<any, Action<any>>
    },
  } as ReducersMapObject<any, any>,
}

export default authModel
