import React from 'react'
import Image from './Image'

const ArrayImage: React.FC<{
  value?: any
  onChange?: (val: any) => void
  onAddClick?: () => void
  schema?: any
}> = (props) => {
  const { ...restProps } = props
  return <Image multiple={true} {...restProps} />
}

export default ArrayImage
