import { addCollection } from '@iconify/react'

import akarIcons from '@iconify/json/json/akar-icons.json'
import antDesignIcons from '@iconify/json/json/ant-design.json'
/* import biIcons from '@iconify/json/json/bi.json'
import bpmnIcons from '@iconify/json/json/bpmn.json'
import brandicoIcons from '@iconify/json/json/brandico.json'
import bxIcons from '@iconify/json/json/bx.json'
import bytesizeIcons from '@iconify/json/json/bytesize.json'
import carbonIcons from '@iconify/json/json/carbon.json'
import charmIcons from '@iconify/json/json/charm.json'
import ci from '@iconify/json/json/ci.json'
import cib from '@iconify/json/json/cib.json'
import cif from '@iconify/json/json/cif.json'
import cil from '@iconify/json/json/cil.json'
import clarity from '@iconify/json/json/clarity.json'
import codicon from '@iconify/json/json/codicon.json'
import cryptocurrency from '@iconify/json/json/cryptocurrency.json'
import dashicons from '@iconify/json/json/dashicons.json'
import ei from '@iconify/json/json/ei.json'
import el from '@iconify/json/json/el.json'
import emojioneMonotone from '@iconify/json/json/emojione-monotone.json' */
// import emojioneV1 from '@iconify/json/json/emojione-v1.json'
/* import emojione from '@iconify/json/json/emojione.json'
import entypoSocial from '@iconify/json/json/entypo-social.json'
import entypo from '@iconify/json/json/entypo.json'
import eosIcons from '@iconify/json/json/eos-icons.json'
import ep from '@iconify/json/json/ep.json'
import et from '@iconify/json/json/et.json'
import eva from '@iconify/json/json/eva.json' */
// import faBrands from '@iconify/json/json/fa-brands.json'
import faRegular from '@iconify/json/json/fa-regular.json'
import faSolid from '@iconify/json/json/fa-solid.json'
/* import fa from '@iconify/json/json/fa.json'
import fad from '@iconify/json/json/fad.json'
import fe from '@iconify/json/json/fe.json' */
/* import feather from '@iconify/json/json/feather.json'
import fileIcons from '@iconify/json/json/file-icons.json'
import flatColorIcons from '@iconify/json/json/flat-color-icons.json'
import flatUIIcons from '@iconify/json/json/flat-ui.json' */
// import fluentIcons from '@iconify/json/json/fluent.json'
/* import fontelicoIcons from '@iconify/json/json/fontelico.json'
import fontistoIcons from '@iconify/json/json/fontisto.json'
import foundationIcons from '@iconify/json/json/foundation.json'
import fxemojiIcons from '@iconify/json/json/fxemoji.json'
import galaIcons from '@iconify/json/json/gala.json'
import geoIcons from '@iconify/json/json/geo.json'
import ggIcons from '@iconify/json/json/gg.json'
import gisIcons from '@iconify/json/json/gis.json'
import gridiconsIcons from '@iconify/json/json/gridicons.json'
import grometIcons from '@iconify/json/json/grommet-icons.json'
import healthIcons from '@iconify/json/json/healthicons.json'
import heroOutlineIcons from '@iconify/json/json/heroicons-outline.json'
import heroSolidIcons from '@iconify/json/json/heroicons-solid.json' */
// import icIcons from '@iconify/json/json/ic.json'
/* import icomoonFreeIcons from '@iconify/json/json/icomoon-free.json'
import packOutlineIcons from '@iconify/json/json/icon-park-outline.json'
import packIcons from '@iconify/json/json/icon-park.json'
import icooirIcons from '@iconify/json/json/iconoir.json'
import icons8Icons from '@iconify/json/json/icons8.json'
import ilIcons from '@iconify/json/json/il.json'
import ionIcons from '@iconify/json/json/ion.json'
import iwwaIcons from '@iconify/json/json/iwwa.json'
import jamIcons from '@iconify/json/json/jam.json'
import laIcons from '@iconify/json/json/la.json'
import lineMdIcons from '@iconify/json/json/line-md.json' */
// import logosIcons from '@iconify/json/json/logos.json'
/* import lsIcons from '@iconify/json/json/ls.json'
import lucideIcons from '@iconify/json/json/lucide.json' */
// import akarIcons from '@iconify/json/json/majesticons.json'
// import akarIcons from '@iconify/json/json/maki.json'
// import akarIcons from '@iconify/json/json/map.json'
/* import mdiLightIcons from '@iconify/json/json/mdi-light.json'
import mdiIcons from '@iconify/json/json/mdi.json'
import medicalIcons from '@iconify/json/json/medical-icon.json'
import miIcons from '@iconify/json/json/mi.json' */
// import akarIcons from '@iconify/json/json/mono-icons.json'
// import akarIcons from '@iconify/json/json/noto-v1.json'
// import akarIcons from '@iconify/json/json/noto.json'
// import akarIcons from '@iconify/json/json/octicon.json'
// import akarIcons from '@iconify/json/json/oi.json'
// import akarIcons from '@iconify/json/json/openmoji.json'
// import akarIcons from '@iconify/json/json/pepicons.json'
// import akarIcons from '@iconify/json/json/ph.json'
// import akarIcons from '@iconify/json/json/pixelarticons.json'
// import akarIcons from '@iconify/json/json/prime.json'
// import akarIcons from '@iconify/json/json/ps.json'
// import akarIcons from '@iconify/json/json/radix-icons.json'
// import akarIcons from '@iconify/json/json/raphael.json'
// import akarIcons from '@iconify/json/json/ri.json'
// import akarIcons from '@iconify/json/json/si-glyph.json'
// import akarIcons from '@iconify/json/json/simple-icons.json'
// import akarIcons from '@iconify/json/json/simple-line-icons.json'
// import akarIcons from '@iconify/json/json/subway.json'
// import akarIcons from '@iconify/json/json/system-uicons.json'
// import akarIcons from '@iconify/json/json/tabler.json'
// import akarIcons from '@iconify/json/json/teenyicons.json'
// import akarIcons from '@iconify/json/json/topcoat.json'
// import akarIcons from '@iconify/json/json/twemoji.json'
// import akarIcons from '@iconify/json/json/typcn.json'
// import akarIcons from '@iconify/json/json/uil.json'
// import akarIcons from '@iconify/json/json/uim.json'
// import akarIcons from '@iconify/json/json/uis.json'
// import akarIcons from '@iconify/json/json/uit.json'
// import akarIcons from '@iconify/json/json/uiw.json'
// import akarIcons from '@iconify/json/json/vaadin.json'
// import akarIcons from '@iconify/json/json/vs.json'
// import akarIcons from '@iconify/json/json/vscode-icons.json'
// import akarIcons from '@iconify/json/json/websymbol.json'
// import akarIcons from '@iconify/json/json/whh.json'
// import akarIcons from '@iconify/json/json/wi.json'
// import akarIcons from '@iconify/json/json/wpf.json'
// import akarIcons from '@iconify/json/json/zmdi.json'
// import akarIcons from '@iconify/json/json/zondicons.json'

// Load icons
addCollection(akarIcons)
addCollection(antDesignIcons)
/* addCollection(biIcons)
addCollection(bpmnIcons)
addCollection(brandicoIcons)
addCollection(bxIcons)
addCollection(bytesizeIcons)
addCollection(carbonIcons)
addCollection(charmIcons)
addCollection(ci)
addCollection(cib)
addCollection(cif)
addCollection(cil)
addCollection(clarity)
addCollection(codicon)
addCollection(cryptocurrency)
addCollection(dashicons)
addCollection(ei)
addCollection(el)
addCollection(emojioneMonotone) */
// addCollection(emojioneV1)
/* addCollection(emojione)
addCollection(entypoSocial)
addCollection(entypo)
addCollection(eosIcons)
addCollection(ep)
addCollection(et)
addCollection(eva) */
// addCollection(faBrands)
addCollection(faRegular)
addCollection(faSolid)
/* addCollection(fa)
addCollection(fad)
addCollection(fe) */
/* addCollection(feather)
addCollection(fileIcons)
addCollection(flatColorIcons)
addCollection(flatUIIcons) */
// addCollection(fluentIcons)
/* addCollection(fontelicoIcons)
addCollection(fontistoIcons)
addCollection(foundationIcons)
addCollection(fxemojiIcons)
addCollection(galaIcons)
addCollection(geoIcons)
addCollection(ggIcons)
addCollection(gisIcons)
addCollection(gridiconsIcons)
addCollection(grometIcons)
addCollection(healthIcons)
addCollection(heroOutlineIcons)
addCollection(heroSolidIcons) */
// addCollection(icIcons)
/* addCollection(icomoonFreeIcons)
addCollection(packOutlineIcons)
addCollection(packIcons)
addCollection(icooirIcons)
addCollection(icons8Icons)
addCollection(ilIcons)
addCollection(ionIcons)
addCollection(iwwaIcons)
addCollection(jamIcons)
addCollection(laIcons)
addCollection(lineMdIcons) */
// addCollection(logosIcons)
/* addCollection(lsIcons)
addCollection(lucideIcons) */
// addCollection( akarIcons ) '@iconify/json/json/majesticons.json'
// addCollection( akarIcons ) '@iconify/json/json/maki.json'
// addCollection( akarIcons ) '@iconify/json/json/map.json'
/* addCollection(mdiLightIcons)
addCollection(mdiIcons)
addCollection(medicalIcons)
addCollection(miIcons) */
