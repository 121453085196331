import ModelSelectWidget from './ModelSelect'
import CheckboxWidget from './Checkbox'
import ImageWidget from './ImageWidget'
import RichTextWidget from './RichTextWidget'
import DatePicker from './DatePicker'
import UploadWidget from './UploadWidget'
import EnumWidget from './EnumWidget'

import MultipleImageWidget from './MultipleImageWidget'
import LocationWidget from './LocationWidget'
import ArrayModelSelect from './ArrayModelSelect'
import ArrayFieldTemplate from './ArrayFieldTemplate'
import RadioGroupWidget from './RadioGroupWidget'
import SliderWidget from './SliderWidget'

const Widgets = {
  ArrayFieldTemplate,
  ArrayModelSelect,
  LocationWidget,
  ModelSelectWidget,
  CheckboxWidget,
  ImageWidget,
  RichTextWidget,
  date: DatePicker,
  UploadWidget,
  EnumWidget,
  MultipleImageWidget,
  RadioGroupWidget,
  SliderWidget,
}

export default Widgets
