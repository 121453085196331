import vi_VN from './vi_VN.json';
import user from './user/user_vi_VN.json';
/* PLOP_INJECT_IMPORT */

export default {
  ...vi_VN,
  ...user,
  /* PLOP_INJECT_EXPORT */

};
